<template>
  <TTView>
    <VRow>
      <VCol>
        <QuestionCard :question="question" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import QuestionCard from '../../../components/multi-level-test/questions/QuestionCard.vue';

export default {
  name: 'LevelsQuestionsView',

  components: {
    QuestionCard,
  },

  data() {
    return {
      loading: false,
      question: {
        title: null,
        description: null,
        levelId: null,
        questionType: null,
      },
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },

    levelId() {
      return this.$route.params.levelId;
    },

    questionId() {
      return this.$route.params.questionId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { questionId } = this;
        const data = { id: questionId };
        const { question } = await this.$di.api.MultiLevelTest.QuestionsGet({ data });

        this.question = question;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
